import { useState, useEffect } from "react";
import moment from "moment";
import AdminUserInfo from "../../service/adminUserService";
import { getUsers, updateUser } from "../../service/userService";
import { Notification } from "../shared/notification";
import { useNavigate } from "react-router-dom";
import "./updateuser.scss";
import { UnionModal } from "../shared/modal/UnionModal";

interface UpdateUserProps {
  isVisible: boolean;
  onCancel: () => void;
  uid: string;
  aid: string;
}

export function UpdateUser({
  isVisible = false,
  onCancel,
  uid,
  aid,
}: Readonly<UpdateUserProps>) {
  let navigate = useNavigate();
  const userId = uid;
  const applicationId = aid;
  const [formErrors] = useState({
    requestedByValid: true,
    accessStartDateTimeValid: true,
    accessEndDateTimeValid: true,
    formValid: false,
    validationError: {
      requestedByError: "",
      accessStartDateTimeError: "",
      accessEndDateTimeError: "",
    },
  });

  const [userInfo, setUserInfo] = useState({
    userEmailId: userId,
    applicationId: applicationId,
    requestedBy: "",
    accessStartDateTime: "",
    accessEndDateTime: "",
  });
  const resetForm = () => {
    setUserInfo({
      userEmailId: "",
      applicationId: "",
      requestedBy: "",
      accessStartDateTime: "",
      accessEndDateTime: "",
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [showModal, setShowModal] = useState(isVisible);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const loadUserData = (userEmailId: any, userApplicationId: any) => {
    setIsLoading(true);
    getUsers()
      .then((res) => {
        let isUserFound = res.some((x: any) => {
          if (
            x.userId === userEmailId &&
            x.applicationId === userApplicationId
          ) {
            setUserInfo({
              userEmailId: x.userId as string,
              applicationId: x.applicationId as string,
              requestedBy: x.requestedBy as string,
              accessStartDateTime: moment(x.startDate).format(
                "yyyy-MM-DDTHH:mm"
              ),
              accessEndDateTime:
                x.endDate === "" ||
                x.endDate === undefined ||
                x.endDate === null
                  ? ""
                  : moment(x.endDate).format("yyyy-MM-DDTHH:mm"),
            });
            setIsLoading(false);
            return true;
          }
          return false;
        });

        if (!isUserFound) {
          setTimeout(() => {
            navigate("/home");
          }, 1);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  useEffect(() => loadUserData(userId, applicationId), [userId, applicationId]);
  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    validateField(name, value);
    setUserInfo((state) => ({ ...state, [name]: value }));
  };

  const validateDate = (date: string) => {
    const dateFormat = "yyyy/MM/DD HH:mm:ss";
    return moment(
      moment(date).format("yyyy/MM/DD HH:mm:ss"),
      dateFormat,
      true
    ).isValid();
  };

  const validateField = (fieldName: any, value: any) => {
    const regx = /^([\w'.+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    switch (fieldName) {
      case "requestedBy":
        handleRequestedByEmailValidation(value, regx);
        break;

      case "accessStartDateTime":
        handleAccessStartDateTimeValidation(value);
        break;

      case "accessEndDateTime":
        handleAccessEndDateTimeValidation(value);
        break;
    }

    updateFormValidity();
  };

  const handleRequestedByEmailValidation = (value: any, regx: RegExp) => {
    const requestedByEmailValid = value.match(regx);
    formErrors.requestedByValid = requestedByEmailValid !== null;
    formErrors.validationError.requestedByError = formErrors.requestedByValid
      ? ""
      : "Requested By Email ID is either null or not in valid format";
  };

  const handleAccessStartDateTimeValidation = (value: any) => {
    if (validateDate(value) && value !== null) {
      if (
        userInfo.accessEndDateTime !== "" &&
        moment.utc(value).format() >=
          moment.utc(userInfo.accessEndDateTime).format()
      ) {
        formErrors.validationError.accessStartDateTimeError =
          "Access Start DateTime can't be greater than or equal the Access End DateTime";
        formErrors.accessStartDateTimeValid = false;
      } else {
        formErrors.validationError.accessStartDateTimeError = "";
        formErrors.validationError.accessEndDateTimeError = "";
        formErrors.accessStartDateTimeValid = true;
        formErrors.accessEndDateTimeValid = true;
      }
    } else {
      formErrors.validationError.accessStartDateTimeError =
        "Access Start DateTime is not valid";
      formErrors.accessStartDateTimeValid = false;
    }
  };

  const handleAccessEndDateTimeValidation = (value: any) => {
    let accessEndDateTimeValid =
      moment.utc(value).format() >
      moment.utc(userInfo.accessStartDateTime).format();

    if (!validateDate(value)) {
      formErrors.validationError.accessEndDateTimeError =
        "Access End DateTime is not valid";
    } else {
      formErrors.validationError.accessEndDateTimeError = "";
    }

    if (value !== null && !accessEndDateTimeValid) {
      formErrors.validationError.accessEndDateTimeError =
        "Access End DateTime can't be less than or equal the Access Start DateTime";
    } else {
      formErrors.validationError.accessEndDateTimeError = "";
      formErrors.validationError.accessStartDateTimeError = "";
      formErrors.accessStartDateTimeValid = true;
    }

    if (formErrors.validationError.accessEndDateTimeError) {
      accessEndDateTimeValid = false;
    }
    formErrors.accessEndDateTimeValid = accessEndDateTimeValid;
  };

  const updateFormValidity = () => {
    formErrors.formValid =
      formErrors.requestedByValid &&
      formErrors.accessStartDateTimeValid &&
      formErrors.accessEndDateTimeValid;
  };

  const handleCancel = () => {
    onCancel();
    resetForm();
    navigate("/home");
  };

  const handleSubmit = async () => {
    const adminUserInfo = AdminUserInfo();

    if (adminUserInfo) {
      const userData: any = {
        userId: userInfo.userEmailId,
        applicationId: userInfo.applicationId,
        requestedBy: userInfo.requestedBy,
        startDate: moment(userInfo.accessStartDateTime).format(
          "yyyy/MM/DD HH:mm:ss"
        ),
        endDate:
          userInfo.accessEndDateTime !== ""
            ? moment(userInfo.accessEndDateTime).format("yyyy/MM/DD HH:mm:ss")
            : "",
        lastUpdatedBy: adminUserInfo.email,
        lastUpdatedOn: moment.utc().format("yyyy/MM/DD HH:mm:ss"),
      };
      setIsLoading(true);
      updateUser(userData)
        .then(() => {
          setIsLoading(false);
          setUserUpdated(true);
          formErrors.formValid = false;
          setTimeout(() => {
            setUserUpdated(false);
          }, 5000);
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    }
  };

  const handleErrorResponse = (error: any) => {
    setIsLoading(false);
    if (error !== null && error !== undefined) {
      if (error.data["error"].errors.length > 0) {
        let errorField = error.data["error"].errors[0].errorField;
        let errorDescription = error.data["error"].errors[0].errorDescription;
        setErrorResponse(errorField + " " + errorDescription);
      }
    } else {
      setErrorResponse("Error occurred while updating user");
    }
    setTimeout(() => {
      setErrorResponse("");
    }, 1000);
    console.error(error);
  };
  const maxInput = "9999-12-31T00:00"
  const minInput = "2020-01-01T00:00"

  return (
    <>
      {userUpdated && (
        <Notification type="success" message="User Updated Successfully" />
      )}
      {errorResponse !== "" && (
        <Notification type="warning" message={errorResponse} />
      )}
      <div className="update-user">
        <form className="form" onSubmit={handleSubmit}>
          {showModal && (
            <UnionModal
              id="update-modal"
              title="Update User"
              size="small"
              actions={[
                {
                  buttonId: "update",
                  buttonLabel: "Update",
                  buttonType: "Primary",
                  disabled: !formErrors.formValid,
                  onButtonClick: () => {
                    handleSubmit();
                    setShowModal(false);
                    handleCancel();
                  },
                  buttonSize: "small",
                },
                {
                  buttonId: "cancel",
                  buttonLabel: "Cancel",
                  buttonType: "Subtle",
                  disabled: false,
                  onButtonClick: handleCancel,
                  buttonSize: "small",
                },
              ]}
              onClose={onCancel}
              actionsLayout={"end"}
            >
              <div className="user-access">
                <div className="email">
                  <label htmlFor="email">Email</label>
                  <input
                    data-testid={"userEmail"}
                    type="email"
                    name="userEmail"
                    value={userInfo.userEmailId ?? ""}
                    onChange={handleChange}
                    placeholder="Requester Email Id"
                    readOnly
                  ></input>
                </div>
                <div className="applicationId">
                  <label htmlFor="application-id">Application Id</label>
                  <input
                    data-testid={"applicationId"}
                    type="email"
                    name="applicationId"
                    value={userInfo.applicationId ?? ""}
                    onChange={handleChange}
                    placeholder="Application Id"
                    readOnly
                  ></input>
                </div>
                <div>
                  <label htmlFor="requested-by">Requested By *</label>
                  <input
                    data-testid="requestedBy"
                    type="email"
                    name="requestedBy"
                    value={userInfo.requestedBy}
                    onChange={handleChange}
                    placeholder="Requester Email Id"
                  ></input>
                </div>

                <div>
                  <label htmlFor="access-start-date-time">Access Start DateTime (UTC) *</label>
                  <input
                    data-testid={"accessStartDateTime"}
                    type="datetime-local"
                    name="accessStartDateTime"
                    value={userInfo.accessStartDateTime}
                    onChange={handleChange}
                    min= {minInput}
                    max={maxInput}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  ></input>
                </div>
                <div>
                  <label htmlFor="access-end-date-time">Access End DateTime (UTC-Optional)</label>
                  <input
                    id="accessEndDateTime"
                    data-testid="accessEndDateTime"
                    type="datetime-local"
                    name="accessEndDateTime"
                    value={userInfo.accessEndDateTime}
                    onChange={handleChange}
                    min={userInfo.accessStartDateTime}
                    max={maxInput}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  ></input>
                </div>
              </div>

              {formErrors.formValid === false && (
                <div
                  className="error"
                  id="validationError"
                  data-testid="validationError"
                >
                  <p>{formErrors.validationError.requestedByError}</p>
                  <p>{formErrors.validationError.accessStartDateTimeError}</p>
                  <p>{formErrors.validationError.accessEndDateTimeError}</p>
                </div>
              )}
              {isLoading && <div id="loading">Loading...</div>}
            </UnionModal>
          )}
        </form>
      </div>
    </>
  );
}
