import { User } from "../model/user.model";
const axios = require("axios");

export async function getUsers() {

  let apiUrl = getApiUrl();
  let usersUrl = apiUrl + "/UserProductPermissions";

  try {
    const response = await axios.get(
      usersUrl,
    ); 
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createUser(user: User) {

  let apiUrl = getApiUrl();
  let createUserUrl = apiUrl + "/UserProductPermission";

  try {
    const response = await axios.post(
      createUserUrl,
      user
    );
    return response?.data;
  } catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
}

export async function updateUser(user: User) {

  let apiUrl = getApiUrl();
  let updateUserUrl = apiUrl + "/UserProductPermission";

  try {
    const response = await axios.put(
      updateUserUrl,
      user
    );
    return response?.data;
  } catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
}

export async function deleteUser(userId: string, applicationId: string) {

  let apiUrl = getApiUrl();
  let deleteUserUrl = apiUrl + "/UserProductPermission/" + userId + "/" + applicationId;

  try {
    const response = await axios.delete(
      deleteUserUrl
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function getApiUrl() {
  let url = window.location.hostname;
  switch (url) {
    case 'unionadmin.qa.finapps.ihsmarkit.com':
      return "https://api-admin.qa.finapps.ihsmarkit.com";
    case 'unionadmin.uat.finapps.ihsmarkit.com':
      return "https://api-admin.uat.finapps.ihsmarkit.com";
    case 'unionadmin.finapps.ihsmarkit.com':
      return "https://api-admin.finapps.ihsmarkit.com";
    default:
      return "https://api-admin.dev.finapps.ihsmarkit.com";
  }
}
